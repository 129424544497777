@media (width >= 1024px) {
  .segundaParte__image {
    width: 78.4rem;
  }

  .desk2__picture {
    width: 160rem;
  }

  .logo img {
    width: 10.4rem;
  }

  .segundaParte__picture {
    width: 78.4rem;
  }

  .br {
    display: none;
  }

  .text-mb {
    margin-bottom: 0;
  }

  #titulo--mobile {
    display: none;
  }

  #titulo--desk {
    display: flex;
  }

  a.btnVisitaSitio {
    display: none;
  }

  .heroContainer {
    background-color: var(--white-web-space);
    justify-content: space-between;
    margin-bottom: 0;
    padding-bottom: 21.4rem;
    display: flex;
  }

  .heroContainer__col--left {
    display: flex;
  }

  .heroContainer__texto .heroContainer__texto--1 {
    color: #000;
    width: 41.1rem;
    height: 2.4rem;
    margin-top: 18.7rem;
    font-family: Akzidenz-Grotesk Pro Regular, sans-serif;
    font-size: 2.4rem;
    font-weight: 400;
  }

  .heroContainer__texto .heroContainer__texto--2 {
    width: 72.9rem;
    height: 6.8rem;
    margin-bottom: 0;
    line-height: 3.4rem;
  }

  .heroContainer__leftImage, .heroContainer__rightImage {
    height: 14.58vw;
  }

  .heroContainer__col--left {
    width: 26.66vw;
  }

  .heroContainer__col--right {
    width: 20.85vw;
  }

  .heroContainer__leftImage--first {
    background: #d3d3d3 url("persona1.d5488fc9.webp") 50% / cover no-repeat;
  }

  .heroContainer__leftImage--second {
    background: #d3d3d3 url("persona2.2cb1c384.webp") 50% / cover no-repeat;
  }

  .heroContainer__rightImage--first {
    background: #d3d3d3 url("persona3.0d2d6294.webp") 50% / cover no-repeat;
  }

  .heroContainer__rightImage--second {
    background: #d3d3d3 url("persona4.11cd9dca.webp") 50% / cover no-repeat;
  }

  .heroContainer__texto h1 {
    max-width: initial;
    width: 71.2rem;
    height: 15.2rem;
    margin-top: 3.2rem;
    font-family: Akzidenz-Grotesk Pro Med;
    font-size: 7rem;
    font-weight: 500;
    line-height: 7.5rem;
  }

  .heroContainer__texto {
    padding-left: 16rem;
  }

  .heroContainer__texto p {
    color: #0009;
    max-width: initial;
    width: 72.9rem;
    height: 6.8rem;
    margin-top: 4.8rem;
    font-family: Akzidenz-Grotesk Pro Light;
    font-size: 3rem;
    font-weight: 300;
  }

  .heroContainer__imagenes img {
    border-radius: 5rem;
    width: 51.2rem;
    height: 28rem;
    margin-bottom: 4rem;
    box-shadow: 1.6rem 1.6rem 2rem #0000001a;
  }

  .heroContainer__imagenes .heroContainer__col2 {
    margin-top: 14.4rem;
  }

  .heroContainer .btnWidth {
    margin-top: 11.6rem;
  }

  .heroContainer .btnWidth p {
    width: 100%;
    padding-left: 0;
    font-family: Akzidenz-Grotesk Pro Ext, sans-serif;
    transition: all .2s ease-in;
  }

  .btnWidth p:hover {
    font-size: 2.8rem;
  }

  .barraSeparadora {
    display: none;
  }

  .segundaParte {
    margin-left: 16rem;
  }

  .titulo--margen {
    height: 10rem;
  }

  .desk1--grupo1 {
    grid-template-columns: repeat(3, 1fr);
    justify-content: start;
    align-items: start;
    margin-top: 9.7rem;
    display: grid;
  }

  .segundaParte__titulo {
    color: var(--violet-dark-web-space);
    justify-content: start;
    align-items: center;
    padding: 0;
    font-family: Akzidenz-Grotesk Pro Med;
    font-size: 8rem;
    font-weight: 500;
  }

  .titulo--margen2 {
    height: 10rem;
    margin-bottom: 10.3rem;
  }

  .titulo--margen3 {
    margin-top: 18rem;
    margin-left: 16rem;
  }

  .desk2--grupo {
    place-items: center;
    margin-top: 3.8rem;
    margin-bottom: 0;
    padding-left: 16rem;
    padding-right: 16rem;
    display: grid;
  }

  .titulo--height {
    width: 122.2rem;
    height: 30.3rem;
    margin-top: 10.3rem;
    padding: 0;
    line-height: 10.1rem;
  }

  .segundaParte__p1 {
    color: #0009;
    background-color: #fff;
    margin-top: 3.6rem;
    margin-bottom: 5.6rem;
    font-family: Akzidenz-Grotesk Pro Light;
    font-size: 3rem;
    font-weight: 300;
    line-height: 3rem;
  }

  .texto--height {
    line-height: 3.6rem;
  }

  .img-desk2 {
    margin-top: 14.4rem;
    margin-bottom: 16.8rem;
  }

  .SegundaParte--desk2 {
    grid-template-columns: 1fr 1fr;
    column-gap: 4rem;
    padding-left: 16rem;
    padding-right: 16rem;
    display: grid;
  }

  .SegundaParte--desk4 {
    justify-content: start;
  }

  .SegundaParte--desk3 {
    justify-content: space-between;
    place-items: start;
    margin-top: 13.9rem;
    margin-left: 16rem;
    margin-right: 16rem;
    display: flex;
  }

  .containerCliente img {
    width: 10rem;
  }

  .centrado--2 {
    text-align: start;
    place-items: start;
    display: grid;
  }

  .asd {
    width: 78.4rem;
    height: 78.4rem;
  }

  .centrado--1 {
    place-items: start;
    margin-bottom: 8rem;
    display: grid;
  }

  .centrado--3 {
    justify-content: space-between;
    margin-left: 16rem;
    margin-right: 16rem;
    display: flex;
  }

  .centrado--4 {
    place-items: start;
    height: 100%;
    margin-left: 4.1rem;
    display: grid;
  }

  a.btnConocePropuesta2 {
    display: none;
  }

  .centrado--1 .ilustracion--margen {
    margin-top: 0;
  }

  .texto--margen {
    margin-top: 2.5rem;
  }

  .titulo--margen1 {
    height: 10rem;
    margin-top: 10.8rem;
    margin-bottom: 5.8rem;
  }

  .margen--desk {
    margin-top: 18rem;
  }

  .desk--texto1 {
    color: #0009;
    width: 56rem;
    margin-top: 0;
    font-size: 3.2rem;
  }

  .btn--centrado {
    justify-content: start;
    align-items: start;
    height: auto;
    margin: 0;
    display: flex;
  }

  a.btnEmpezarAhora {
    margin: 0;
  }

  .texto1--height1 {
    width: 56rem;
    height: 21rem;
    line-height: 4.3rem;
  }

  .texto1--height2 {
    width: 55.5rem;
    height: 16.8rem;
    margin-left: 8rem;
    margin-right: 8.5rem;
    line-height: 4.3rem;
  }

  .segundaParte__p2 {
    color: #000;
    width: 156.4rem;
    height: 8.3rem;
    margin-top: 2.4rem;
    font-size: 3.2rem;
    font-weight: 300;
    line-height: 4.2rem;
  }

  .segundaParte__ilustracion1 {
    width: 12rem;
    height: 12rem;
    margin-top: 7.2rem;
  }

  .segundaParte__subtitulo {
    color: var(--violet-dark-web-space);
    text-align: start;
    width: 32rem;
    height: 6.8rem;
    margin-top: 2.4rem;
    font-family: Akzidenz-Grotesk Pro Med;
    font-size: 3.2rem;
    font-weight: 500;
    line-height: 3.5rem;
  }

  .p1--resaltado {
    font-weight: 400;
  }

  .align-center {
    text-align: start;
  }

  .ilustracion1--variante {
    width: 9.611rem;
    height: 7.915rem;
    margin-top: 0;
    margin-bottom: 1.76rem;
  }

  .texto--margen2 {
    margin-top: 3.6rem;
  }

  .subtitulo--tamaño {
    width: 55.6rem;
    margin-top: 2.143rem;
  }

  .segundaParte__imagen1 {
    margin-top: 14.4rem;
    margin-bottom: 16.8rem;
  }

  .segundaParte__clientes {
    width: 44rem;
    margin-bottom: 1.6rem;
    font-size: 3rem;
  }

  .clientes__texto {
    color: #0009;
    text-align: start;
    width: 51.9rem;
    height: 16rem;
    margin-bottom: 4.8rem;
    font-family: Akzidenz-Grotesk Pro Light;
    font-size: 3rem;
    font-weight: 300;
    line-height: 4.2rem;
  }

  .btn--desk2 {
    justify-content: center;
    margin-top: 12.8rem;
    margin-bottom: 16.8rem;
    display: flex;
  }

  .imagen--descripcion {
    text-align: start;
    color: #000;
    width: 77.6rem;
    height: 50.4rem;
    margin-top: 0;
    margin-bottom: 14.4rem;
    font-family: Akzidenz-Grotesk Pro Light, sans-serif;
    font-size: 4.8rem;
    font-style: italic;
    font-weight: 300;
    line-height: 6.4rem;
  }

  .texto--1 {
    color: #000;
    align-items: center;
    height: 3.1rem;
    margin-bottom: .9rem;
    font-family: Akzidenz-Grotesk Pro Med Ext;
    font-size: 2.4rem;
    font-weight: 500;
    display: flex;
  }

  .texto--2 {
    align-items: center;
    height: 3.1rem;
    font-family: Akzidenz-Grotesk Pro Light;
    font-size: 2.4rem;
    display: flex;
  }

  .texto--3 {
    line-height: 3.6rem;
  }

  .texto--4 {
    width: 77.7rem;
  }

  .texto--5 {
    width: 77.2rem;
  }

  .containerCliente__texto {
    flex-direction: column;
    justify-content: center;
    margin-left: 2.4rem;
    display: flex;
  }

  .segundaParte__ultTitulo {
    text-align: center;
    background: var(--violet-light-gradient);
    color: #0000;
    background-clip: text;
    width: 76.1rem;
    height: 21.3rem;
    margin-top: 23.5rem;
    margin-bottom: 6.5rem;
    font-family: Akzidenz-Grotesk Pro Med Ext;
    font-size: 5.6rem;
    font-weight: 500;
    line-height: 7.1rem;
  }

  .containerPalabras {
    column-gap: 13.5rem;
    margin-top: 11.5rem;
    margin-bottom: 7.6rem;
    display: flex;
  }

  .containerPalabras__elem {
    align-items: center;
    gap: 1.6rem;
    display: flex;
  }

  .elem__container {
    width: 4rem;
    height: 4rem;
  }

  .tick {
    width: 2.5rem;
    height: 2.5rem;
  }

  .containerPalabras__elem p {
    color: #000;
    font-family: Akzidenz-Grotesk Pro Light Ext;
    font-size: 3.2rem;
    font-weight: 300;
  }

  .barra--margen1 {
    margin: 3.2rem 1.6rem .8rem;
  }

  .barra--margen2 {
    margin-bottom: 1.6rem;
    margin-left: 1.6rem;
    margin-right: 1.6rem;
    position: relative;
    top: -3.2rem;
  }

  .titulo--flecha:before {
    content: "";
    background-image: url("flecha-titulo.59c5e805.svg");
    background-repeat: no-repeat;
    background-size: cover;
    width: 14.2rem;
    height: 1.5rem;
    margin-right: 1.9rem;
  }
}
/*# sourceMappingURL=index.6aea9465.css.map */
