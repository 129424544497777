@import url("index.css");

@media (min-width: 1024px) {
	.segundaParte__image {
		width: 78.4rem;
	}

	.desk2__picture {
		width: 160rem;
	}

	.logo img {
		width: 10.4rem;
	}

	.segundaParte__picture {
		width: 78.4rem;
	}

	.br {
		display: none;
	}

	.text-mb {
		margin-bottom: 0;
	}

	#titulo--mobile {
		display: none;
	}
	#titulo--desk {
		display: flex;
	}

	a.btnVisitaSitio {
		display: none;
	}

	.heroContainer {
		display: flex;
		background-color: var(--white-web-space);
		padding-bottom: 21.4rem;
		margin-bottom: 0;
		justify-content: space-between;
	}

	.heroContainer__col--left {
		display: flex;
	}

	.heroContainer__texto .heroContainer__texto--1 {
		margin-top: 18.7rem;
		font-family: "Akzidenz-Grotesk Pro Regular", sans-serif;
		font-size: 2.4rem;
		font-weight: 400;
		color: #000000;
		width: 41.1rem;
		height: 2.4rem;
	}

	.heroContainer__texto .heroContainer__texto--2 {
		width: 72.9rem;
		height: 6.8rem;
		line-height: 3.4rem;
		margin-bottom: 0;
	}

	.heroContainer__leftImage {
		height: 14.58vw;
	}

	.heroContainer__rightImage {
		height: 14.58vw;
	}

	.heroContainer__col--left {
		width: 26.66vw;
	}

	.heroContainer__col--right {
		width: 20.85vw;
	}

	.heroContainer__leftImage--first {
		background: url("../../../assets/images/index/persona1.png?as=webp&width=512")
			lightgray 50% / cover no-repeat;
	}

	.heroContainer__leftImage--second {
		background: url("../../../assets/images/index/persona2.png?as=webp&width=512")
			lightgray 50% / cover no-repeat;
	}

	.heroContainer__rightImage--first {
		background: url("../../../assets/images/index/persona3.png?as=webp&width=512")
			lightgray 50% / cover no-repeat;
	}

	.heroContainer__rightImage--second {
		background: url("../../../assets/images/index/persona4.png?as=webp&width=512")
			lightgray 50% / cover no-repeat;
	}

	.heroContainer__texto h1 {
		margin-top: 3.2rem;
		font-family: "Akzidenz-Grotesk Pro Med";
		font-size: 7rem;
		font-weight: 500;
		width: 71.2rem;
		height: 15.2rem;
		line-height: 7.5rem;
		max-width: initial;
	}

	.heroContainer__texto {
		padding-left: 16rem;
	}

	.heroContainer__texto p {
		margin-top: 4.8rem;
		font-family: "Akzidenz-Grotesk Pro Light";
		font-size: 3rem;
		font-weight: 300;
		width: 72.9rem;
		height: 6.8rem;
		color: #00000099;
		max-width: initial;
	}

	.heroContainer__imagenes img {
		width: 51.2rem;
		height: 28rem;
		border-radius: 5rem;
		box-shadow: #0000001a 1.6rem 1.6rem 2rem;
		margin-bottom: 4rem;
	}

	.heroContainer__imagenes .heroContainer__col2 {
		margin-top: 14.4rem;
	}

	.heroContainer .btnWidth {
		margin-top: 11.6rem;
	}

	.heroContainer .btnWidth p {
		font-family: "Akzidenz-Grotesk Pro Ext", sans-serif;
		width: 100%;
		padding-left: 0;
		transition: all 200ms ease-in;
	}

	.btnWidth p:hover {
		font-size: 2.8rem;
	}

	/* debajo del Hero */

	.barraSeparadora {
		display: none;
	}

	.segundaParte {
		margin-left: 16rem;
	}

	.titulo--margen {
		height: 10rem;
	}

	.desk1--grupo1 {
		display: grid;
		grid-template-columns: repeat(3, 1fr);
		justify-content: start;
		align-items: start;
		margin-top: 9.7rem;
	}

	.segundaParte__titulo {
		font-size: 8rem;
		font-weight: 500;
		color: var(--violet-dark-web-space);
		font-family: "Akzidenz-Grotesk Pro Med";
		justify-content: start;
		padding: 0;
		align-items: center;
	}

	.titulo--margen2 {
		margin-bottom: 10.3rem;
		height: 10rem;
	}

	.titulo--margen3 {
		margin-left: 16rem;
		margin-top: 18rem;
	}

	.desk2--grupo {
		display: grid;
		place-items: center;
		margin-top: 3.8rem;
		margin-bottom: 0;
		padding-left: 16rem;
		padding-right: 16rem;
	}

	.titulo--height {
		width: 122.2rem;
		height: 30.3rem;
		line-height: 10.1rem;
		margin-top: 10.3rem;
		padding: 0;
	}

	.segundaParte__p1 {
		font-size: 3rem;
		margin-top: 3.6rem;
		margin-bottom: 5.6rem;
		line-height: 3rem;
		font-weight: 300;
		font-family: "Akzidenz-Grotesk Pro Light";
		color: #00000099;
		background-color: white;
	}

	.texto--height {
		line-height: 3.6rem;
	}

	.img-desk2 {
		margin-top: 14.4rem;
		margin-bottom: 16.8rem;
	}

	.SegundaParte--desk2 {
		display: grid;
		grid-template-columns: 1fr 1fr;
		padding-left: 16rem;
		padding-right: 16rem;
		column-gap: 4rem;
	}

	.SegundaParte--desk4 {
		justify-content: start;
	}

	.SegundaParte--desk3 {
		display: flex;
		place-items: start;
		margin-left: 16rem;
		margin-right: 16rem;
		margin-top: 13.9rem;
		justify-content: space-between;
	}

	.containerCliente img {
		width: 10rem;
	}

	.centrado--2 {
		text-align: start;
		display: grid;
		place-items: start;
	}

	.asd {
		width: 78.4rem;
		height: 78.4rem;
	}

	.centrado--1 {
		display: grid;
		place-items: start;
		margin-bottom: 8rem;
	}

	.centrado--3 {
		display: flex;
		margin-left: 16rem;
		margin-right: 16rem;
		justify-content: space-between;
	}

	.centrado--4 {
		display: grid;
		place-items: start;
		margin-left: 4.1rem;
		height: 100%;
	}

	a.btnConocePropuesta2 {
		display: none;
	}

	.centrado--1 .ilustracion--margen {
		margin-top: 0;
	}

	.texto--margen {
		margin-top: 2.5rem;
	}

	.titulo--margen1 {
		margin-top: 10.8rem;
		margin-bottom: 5.8rem;
		height: 10rem;
	}

	.margen--desk {
		margin-top: 18rem;
	}

	.desk--texto1 {
		font-size: 3.2rem;
		color: #00000099;
		width: 56rem;
		margin-top: 0;
	}

	.btn--centrado {
		margin: 0;
		height: auto;
		display: flex;
		align-items: start;
		justify-content: start;
	}

	a.btnEmpezarAhora {
		margin: 0;
	}

	.texto1--height1 {
		height: 21rem;
		line-height: 4.3rem;
		width: 56rem;
	}

	.texto1--height2 {
		height: 16.8rem;
		line-height: 4.3rem;
		margin-left: 8rem;
		margin-right: 8.5rem;
		width: 55.5rem;
	}

	.segundaParte__p2 {
		width: 156.4rem;
		height: 8.3rem;
		font-weight: 300;
		font-size: 3.2rem;
		line-height: 4.2rem;
		margin-top: 2.4rem;
		color: #000000;
	}

	.segundaParte__ilustracion1 {
		width: 12rem;
		height: 12rem;
		margin-top: 7.2rem;
	}

	.segundaParte__subtitulo {
		font-weight: 500;
		font-size: 3.2rem;
		line-height: 3.5rem;
		color: var(--violet-dark-web-space);
		font-family: "Akzidenz-Grotesk Pro Med";
		margin-top: 2.4rem;
		text-align: start;
		width: 32rem;
		height: 6.8rem;
	}

	.p1--resaltado {
		font-weight: 400;
	}

	.align-center {
		text-align: start;
	}

	.ilustracion1--variante {
		width: 9.611rem;
		height: 7.915rem;
		margin-top: 0;
		margin-bottom: 1.76rem;
	}

	.texto--margen2 {
		margin-top: 3.6rem;
	}

	.subtitulo--tamaño {
		width: 55.6rem;
		margin-top: 2.143rem;
	}

	.segundaParte__imagen1 {
		margin-top: 14.4rem;
		margin-bottom: 16.8rem;
	}

	.segundaParte__clientes {
		font-size: 3rem;
		margin-bottom: 1.6rem;
		width: 44rem;
	}

	.clientes__texto {
		font-size: 3rem;
		font-weight: 300;
		color: #00000099;
		font-family: "Akzidenz-Grotesk Pro Light";
		width: 51.9rem;
		height: 16rem;
		text-align: start;
		line-height: 4.2rem;
		margin-bottom: 4.8rem;
	}

	.btn--desk2 {
		display: flex;
		justify-content: center;
		margin-top: 12.8rem;
		margin-bottom: 16.8rem;
	}

	.imagen--descripcion {
		font-size: 4.8rem;
		font-weight: 300;
		line-height: 6.4rem;
		font-family: "Akzidenz-Grotesk Pro Light", sans-serif;
		font-style: italic;
		width: 77.6rem;
		height: 50.4rem;
		text-align: start;
		margin-bottom: 14.4rem;
		color: #000000;
		margin-top: 0;
	}

	.texto--1 {
		font-size: 2.4rem;
		font-weight: 500;
		font-family: "Akzidenz-Grotesk Pro Med Ext";
		margin-bottom: 0.9rem;
		color: #000000;
		height: 3.1rem;
		display: flex;
		align-items: center;
	}

	.texto--2 {
		font-size: 2.4rem;
		font-family: "Akzidenz-Grotesk Pro Light";
		height: 3.1rem;
		display: flex;
		align-items: center;
	}

	.texto--3 {
		line-height: 3.6rem;
	}

	.texto--4 {
		width: 77.7rem;
	}

	.texto--5 {
		width: 77.2rem;
	}

	.containerCliente__texto {
		display: flex;
		flex-direction: column;
		justify-content: center;
		margin-left: 2.4rem;
	}

	.segundaParte__ultTitulo {
		font-size: 5.6rem;
		font-weight: 500;
		line-height: 7.1rem;
		width: 76.1rem;
		height: 21.3rem;
		margin-top: 23.5rem;
		text-align: center;
		font-family: "Akzidenz-Grotesk Pro Med Ext";
		margin-bottom: 6.5rem;
		background: var(--violet-light-gradient);
		-webkit-background-clip: text;
		background-clip: text;
		color: transparent;
	}

	.containerPalabras {
		display: flex;
		margin-top: 11.5rem;
		margin-bottom: 7.6rem;
		column-gap: 13.5rem;
	}

	.containerPalabras__elem {
		display: flex;
		align-items: center;
		gap: 1.6rem;
	}

	.elem__container {
		width: 4rem;
		height: 4rem;
	}

	.tick {
		width: 2.5rem;
		height: 2.5rem;
	}

	.containerPalabras__elem p {
		font-size: 3.2rem;
		color: #000000;
		font-weight: 300;
		font-family: "Akzidenz-Grotesk Pro Light Ext";
	}

	.barra--margen1 {
		margin-top: 3.2rem;
		margin-bottom: 0.8rem;
		margin-left: 1.6rem;
		margin-right: 1.6rem;
	}

	.barra--margen2 {
		position: relative;
		top: -3.2rem;
		margin-bottom: 1.6rem;
		margin-left: 1.6rem;
		margin-right: 1.6rem;
	}

	.titulo--flecha::before {
		content: "";
		background-image: url("../../../assets/images/svg/flecha-titulo.svg");
		background-repeat: no-repeat;
		background-size: cover;
		height: 1.5rem;
		width: 14.2rem;
		margin-right: 1.9rem;
	}
}
